/* tailwind css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.logo {
  width: 150px;
  height: auto;
  margin-left: 10px;
}

.logs{
  background-color: white;
  margin: 40px;
  padding: 40px;
}


body {
  font-family: 'Arial', sans-serif;
  background-color: rgb(84, 182, 187);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
height: auto;
  margin: 0;
}

.app {
  min-width: 510px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 5px 5px 15px rgba(231, 231, 21, 0.2);
}

h3 {
  text-align: center;
  margin-bottom: 20px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.option {
  padding: 8px 20px;
  margin: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.option.selected {
  background-color: #007BFF;
  color: white;
  }

.option:hover {
  background-color: #007BFF;
  color: white;
}


input[type="text"] {
  width: 80%;
  padding: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Add a container for the buttons */
.button-container {
  display: flex; /* Use flexbox to make the buttons align horizontally */
  justify-content: space-between; /* Add space between the buttons */
  margin-top: 20px; /* Add some margin to separate them from other elements */
}

.next-btn,
.back-btn {
  padding: 8px 20px;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Remove the margin from the Next button */
.next-btn {
  margin-right: 0;
}

.next-btn:hover,.back-btn:hover {
  background-color: #0056b3;
}

.next-btn:disabled,.back-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.head{
  display:flex;
  align-items: center;
  justify-content: center;
}

.heading{
  text-align: center;
  color:rgb(84, 182, 187);
  font-weight: bold;
  font-size: x-large;
}

.initialize-container{
  display: flex;
  align-items: end;
  justify-content: space-around;
}

.space{
  margin:10px;
}

.question-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container{
  max-width: 700px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.image-container > * {
  margin: 10px;
  box-sizing: border-box;
  max-width: 150px;
}

.answer{
  display: flex;
  align-items: end;
  justify-content: space-around;
}

.answers-container{
  text-align: center;
}

/* Style the Sign Out button */
.sign-out-container button {
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 20px;
  margin: 10px;
  transition: background-color 0.2s;
}


/* selfies */

.captured-selfies-container {
  display: flex;
  flex-wrap: wrap;
  border: solid 2px #007BFF;
  text-align: center;
}

.captured-selfie {
  margin: 5px;
  width: 47%;
}

.captured-selfie img {
  max-width: 100%;
  height: auto;
}
